import './squareupappointments.css'

const SquareUpAppointments = () => {
    return (
    <section className="section">
        <div className="content">
        <h2 className="heading">Book Your Appointment Today!</h2>
        <p className="text">
            Get personalized service at your convenience. Click below to schedule
            your appointment with us.
        </p>
        <a
            target="_blank"
            className="button"
            href="https://app.tintwiz.com/web/ce/c47vsuez7tzocd2zu0ipefp8t0l5iu2p"
            rel="nofollow"
        >
            Book Now
        </a>
        </div>
    </section>
    );
};

export default SquareUpAppointments;