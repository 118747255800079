import "./floatingsquareappointmentsbutton.css"; // Import the CSS file

const FloatingSquareAppointmentsButton = () => {
return (
    <a
    target="_blank"
    className="floating-button"
    href="https://app.tintwiz.com/web/ce/c47vsuez7tzocd2zu0ipefp8t0l5iu2p"
    rel="nofollow"
    >
    Book Now
    </a>
);
};


export default FloatingSquareAppointmentsButton;